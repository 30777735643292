

export const handleEncryptData = (
  key: string,
  userData: any,
  secretKey: string
) => {

};

export const getDecryptedObject = (key: string, secretKey: string) => {
  const ciphertext = localStorage.getItem(key);
 
  return null;
};





