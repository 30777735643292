import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";
import loadingGif from "./assets/loading/loadEhb.gif"


const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
(
  <Suspense fallback={
    <div
      className="d-flex justify-center align-center"
      style={{
        height: "100vh",
        width: "100%",
        position: 'fixed',
        left: "0",
        top: "0",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        <img src={loadingGif} width={150} style={{ filter: "grayscale(1) brightness(8.5)" }} alt="" />
        <h3 style={{ color: "#6f757b", marginTop: "-29px" }}>EHB</h3>
      </div>
    </div>
  }>
    <Component {...props} />
  </Suspense>

);
const MainLayout = Loadable(lazy(() => import("./layout/mainLayout")));
const LandingPage = Loadable(lazy(() => import("./pages/landingPage")));
const ProjectsPage = Loadable(lazy(() => import("./pages/projects")));
const TermsAndConditions = Loadable(lazy(() => import("./pages/termsAndConditions")));
const AboutUs = Loadable(lazy(() => import("./pages/aboutUs")));
const RefundPolicy = Loadable(lazy(() => import("./pages/refundPolicy")));
const Faqs = Loadable(lazy(() => import("./pages/faqs")));
const ContactUs = Loadable(lazy(() => import("./pages/contactUs")));
const BusinessModal = Loadable(lazy(() => import("./pages/businessModal")));


export const routes: any = [
  { path: "/", element: <Navigate to="home" /> },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "home",
        element: <LandingPage />,
      },
      {
        path: "projects",
        element: <ProjectsPage />,
      },
      {
        path: "projects/departments",
        element: <ProjectsPage />,
      },
      {
        path: "terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "about-us",
        element: <AboutUs />,
      },
      {
        path: "business-modal",
        element: <BusinessModal />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "refund-policy",
        element: <RefundPolicy />,
      },
      {
        path: "faqs",
        element: <Faqs />,
      },
    ],
  },
];
